import React, { useState, useEffect, useMemo, useContext } from 'react';

import { DataGridPremium, GridToolbar, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-premium';

import { MapContext } from '../../contexts/map';

const tsData = (ms) => {
    if (!ms) return {};

    const d = new Date(Number(ms));

    return {
        ts: d.toISOString().slice(12, -1) + ' UTC',
        tsLocal: d.toLocaleTimeString()
    }
};

const CustomDataGrid = ({ rows, columns }) => {
    const { actions } = useContext(MapContext);
    const fRows = useMemo(() => rows.map(row => ({
        ...row,
        lat: row.lat ? row.lat + ' °' : row.lat,
        lon: row.lon ? row.lon + ' °' : row.lon,
        track: row.track ? row.track + ' °' : row.track,
        ...tsData(row.ts)
    })), [rows])

    const onStateChange = (state) => {
        actions.handleVisibleRows(gridVisibleSortedRowIdsSelector(state).map(id => state.rows.idRowsLookup[id]));
    }

    return (
        <DataGridPremium
            components={{ Toolbar: GridToolbar }}
            rows={fRows}
            columns={columns}
            pageSize={25}
            rowHeight={20}
            rowsPerPageOptions={[25]}
            headerHeight={24}
            rowCount={Math.min(rows.length, 25)}
            componentsProps={{
                panel: {
                    style: {
                        paddingRight: '50px'
                    }
                }
            }}
            density='compact'
            onStateChange={onStateChange}
            sx={{
                '.MuiDataGrid-columnHeaders': {
                    backgroundColor: '#006494',
                    color: 'white',
                    borderRadius: '0',
                }
            }}
        />
    );
}

export default CustomDataGrid;