import React, { useState, useEffect, useRef } from 'react';

const useInterval = (callback, interval) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id;
        if (interval) {
            id = setInterval(tick, interval);
        }

        return () => clearInterval(id);
    }, [interval]);
}

export default useInterval