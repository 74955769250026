import React from 'react';

import Nav from '../components/Nav';
import MapView from '../views/Map/index';
import SideTray from '../components/Trays/SideTray';
import CustomAlerts from '../components/Alerts';

const MainLayout = () => {
    const navHeight = 40;

    return (
        <>
            <Nav navHeight={navHeight} />
            <div style={{ width: '100vw', height: window.innerHeight - navHeight }}>
                <MapView />
                <SideTray />
            </div>
            <CustomAlerts />
        </>
    );
}

export default MainLayout;