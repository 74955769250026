import React, { useState, useEffect, createContext } from 'react';

export const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const create = (payload) => {
        setMessages(prev => [...prev, payload]);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessages(prev => prev.slice(1));
    };

    const actions = {
        create,
        handleClose
    };

    return (
        <AlertsContext.Provider value={{ messages, actions }}>
            {children}
        </AlertsContext.Provider>
    )
}