import React, { useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Marker,
    InfoWindow
} from "@react-google-maps/api";

import { DemoSocketContext } from '../../contexts/demoSocket';
import { SocketContext } from '../../contexts/socket';
import { MapContext } from '../../contexts/map';

import PlaneIcon from '../../assets/svgs/plane';
import UAVMarker from '../../assets/svgs/uav';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
    Divider,
    Grid,
    Typography
} from '@mui/material';

const Planes = () => {
    const { pathname } = useLocation();
    const { visibleRows, actions } = useContext(MapContext);
    const { rows, socketType } = useContext(pathname.includes("/demo") ? DemoSocketContext : SocketContext);
    const [viewInfo, setViewInfo] = useState();

    const planes = visibleRows ? visibleRows : rows;

    return planes.map(plane => {
        const position = {
            lat: parseFloat(plane.lat),
            lng: parseFloat(plane.lon)
        }

        return (
            <>
                <Marker
                    icon={{
                        path: plane.ecat === 8 ? UAVMarker : PlaneIcon,
                        rotation: parseFloat(plane.track),
                        fillOpacity: 1,
                        strokeOpacity: 0.85,
                        strokeWeight: 2,
                        anchor: new window.google.maps.Point(25, 25),
                    }}
                    position={position}
                    title={plane.ident || plane.icao}
                    key={plane.id}
                    onClick={() => setViewInfo(plane.id)}
                    onRightClick={() => actions.viewIndividualHistory(plane.id)}
                />
                {viewInfo === plane.id ? (
                    <InfoWindow position={position} zIndex={10000}>
                        <Grid container style={{ width: '200px', margin: 0 }} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ display: 'inline-block' }}>{plane.ident || plane.icao}</Typography>
                                {plane.ident ? (
                                    <Typography variant='subtitle1' style={{ display: 'inline-block', marginLeft: '8px' }}>{plane.icao}</Typography>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Altitude: {plane.baroAlt} ft</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Speed: {plane.hVelo} kt</Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                alignItems='center'
                            >
                                <Grid item>
                                    <Typography variant='subtitle2'>vSpeed: {plane.vVelo} fpm</Typography>
                                </Grid>
                                {plane.vVelo >= 25 ? (
                                    <Grid item>
                                        <ArrowDropUpIcon style={{ color: 'green' }} />
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <ArrowDropDownIcon style={{ color: 'red' }} />
                                    </Grid>
                                )}

                            </Grid>
                            {socketType ? (
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>Source: {socketType}</Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </InfoWindow>
                ) : null}
            </>
        )
    });
}

export default React.memo(Planes);