import React, { useState, useEffect, useContext } from 'react';

import { SocketContext } from '../../contexts/socket';

import {
    Grid,
    TextField,
    Button,
    MenuItem
} from '@mui/material';

const protocolOptions = [
    'MQTT',
    'WS',
    'WSS'
];

const typeOptions = [
    'CSV',
    'ADS-B',
    'Flarm',
    'MLAT',
    'REMOTE_ID',
    'AeroJSON: ADSB',
    'AeroJSON: Flarm',
    'AeroJSON: Status',
    'AeroJSON: Sensor'
]

const CreateSocket = (props) => {
    const { establishSocket } = useContext(SocketContext);
    const [proto, setProto] = useState('');
    const [host, setHost] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [topic, setTopic] = useState('');

    const handleSubmit = e => {
        const [hostname, port] = host.split(':');

        establishSocket({
            proto,
            port,
            host: hostname,
            username,
            password,
            dataFormat: type,
            topic
        })
            .then(socketID => {
                console.log(socketID);
                props.viewSocket(socketID);
            })
            .catch(console.error);
    }

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    label='Host'
                    value={host}
                    onChange={e => setHost(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Protocol'
                    value={proto}
                    onChange={e => setProto(e.target.value)}
                    select
                    fullWidth
                >
                    {protocolOptions.map((option, i) => (
                        <MenuItem value={i + 1} key={option}>{option}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Username'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Password'
                    value={password}
                    type='password'
                    onChange={e => setPassword(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Topic'
                    value={topic}
                    onChange={e => setTopic(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Type'
                    value={type}
                    onChange={e => setType(e.target.value)}
                    select
                    fullWidth
                >
                    {typeOptions.map((option, i) => (
                        <MenuItem value={i + 1} key={option}>{option}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={handleSubmit}
                >
                    SUBMIT
                </Button>
            </Grid>
        </>
    );
}

export default CreateSocket;