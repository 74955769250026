import React, { useContext } from 'react';

import { MapContext } from '../../contexts/map';

import {
    Grid,
    TextField,
    IconButton,
    Typography,
    FormControlLabel,
    Switch,
} from '@mui/material';

import AdjustIcon from '@mui/icons-material/Adjust';

export const RingButton = ({ onClick }) => (
    <IconButton onClick={() => onClick('ring')}>
        <AdjustIcon />
    </IconButton>
);

export const RingOverlay = () => {
    const { viewRings, ringsSize, actions: mapActions } = useContext(MapContext);

    return (
        <Grid container justifyContent='center' alignItems='center' spacing={2} sx={{ padding: '16px' }}>
            <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                    Change Ring Size
                </Typography>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Switch checked={viewRings} onChange={mapActions.handleChangeViewRings} />}
                    label='Ring Visibility'
                />
            </Grid>
            {ringsSize.map((s, i) => (
                <Grid container item xs={12} justifyContent='center' alignItems='center' spacing={4} key={i}>
                    <Grid item>
                        Ring #{i + 1}
                    </Grid>
                    <Grid item>
                        <TextField
                            type='number'
                            variant='outlined'
                            value={s}
                            onChange={e => mapActions.adjustRings(Number(e.target.value), i)}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}