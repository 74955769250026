import React from 'react';

import { Popover } from '@mui/material';

const MapPopover = ({ open, handleClose, children }) => (
    <Popover
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: window.innerWidth / 2 }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        {children}
    </Popover>
);

export default MapPopover;