import React, { useContext } from 'react';
import { Polyline } from "@react-google-maps/api";

import { MapContext } from '../../contexts/map';

const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4
};

const LocationHistory = () => {
    const { histMarkers } = useContext(MapContext);
    return Object.entries(histMarkers).map(([icao, markers]) =>(
        <Polyline
            path={markers.map(m => ({ lat: parseFloat(m.lat), lng: parseFloat(m.lon) }))}
            options={{
                strokeOpacity: 0,
                icons: [
                    {
                        icon: lineSymbol,
                        offset: "0",
                        repeat: "25px",
                    },
                ]
            }}
            key={icao}
        />
    ))
}

export default React.memo(LocationHistory);