import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { SocketContext } from '../../contexts/socket';
import { DemoSocketContext } from '../../contexts/demoSocket';

import {
    Grid,
    Button
} from '@mui/material';

import CustomDataGrid from '../../components/DataGrid/CustomDataGrid';

import logo from '../../assets/images/logo.png';

const Dashboard = () => {
    const { pathname } = useLocation();
    const { rows, columns, messagesCount } = useContext(pathname.includes("/demo") ? DemoSocketContext : SocketContext);

    return (
        <Grid container justifyContent='center' spacing={2} style={{ width: '100%', margin: 0, marginTop: '-24px' }}>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item>
                    <b>Total Aircraft:</b> {rows.length}
                </Grid>
                <Grid item>
                    <b>Messages:</b> {messagesCount}
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ minHeight: `70vh` }}>
                <CustomDataGrid rows={rows} columns={columns} />
            </Grid>
            <Grid item>
                <img src={logo} style={{ width: '30%', margin: '0 auto', display: 'block' }} />
            </Grid>
        </Grid>
    );
}

export default Dashboard;