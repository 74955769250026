import React, { useContext } from 'react';

import { AlertsContext } from '../../contexts/alerts';

import {
    Snackbar,
    Alert as MuiAlert,
    IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const CustomAlerts = () => {
    const { messages, actions } = useContext(AlertsContext);
    const [currMessage] = messages;

    return currMessage ? (
        <Snackbar
            open={Boolean(messages.length)}
            autoHideDuration={6000}
            onClose={actions.handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                severity={currMessage.severity}
                sx={{ minWidth: '20vw' }}
                action={
                    <IconButton onClick={actions.handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
            >
                {currMessage.message}
            </Alert>
        </Snackbar>
    ) : null;
}

export default CustomAlerts;