import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained', color: 'secondary' },
                    style: {
                        backgroundImage: 'linear-gradient(90deg, #4CB0F4 0%, #8526FF 100%)'
                    }
                }
            ]
        }
    }
});

const ThemeContext = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default ThemeContext;