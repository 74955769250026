import { Routes, Route } from "react-router-dom";
import useRoutes from "./useRoutes";

const CustomRouter = ({ display }) => {
    const routes = useRoutes();

    return display ? (
        <Routes>
            {routes.map(r => (
                <Route
                    path={r.path}
                    element={r.component}
                    exact={r.exact}
                    key={r.path}
                />
            ))}
        </Routes>
    ) : null;
}

export default CustomRouter;