import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AuthContext, logOut } from '../../contexts/auth';

import {
    Paper,
    Grid,
    IconButton,
    Button
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';

const Nav = ({ navHeight }) => {
    const { pathname } = useLocation();
    const { user } = useContext(AuthContext);

    const isHome = pathname === '/';
    const isDemo = pathname.includes("/demo");

    const signOut = () => {
        logOut()
            .then(() => window.location.pathname = '/')
            .catch(console.error);
    }

    return (
        <Paper elevation={1} style={{ width: '100vw', height: `${navHeight}px`, textAlign: 'center', background: 'rgba(255, 255, 255, 0.85)' }}>
            <Grid container justifyContent='space-between' alignItems='center' style={{ height: '100%', padding: '8px' }}>
                <Grid item>
                    {(user && !isDemo) ? (
                        <Button onClick={signOut}>
                            LOG OUT
                        </Button>
                    ) : null}
                </Grid>
                <Grid item style={{ marginTop: '-10px' }}>
                    <p style={{ textAlign: 'center' }}>remote-id</p>
                </Grid>
                <Grid item>
                    {(user && !isDemo) ? (
                        <IconButton size='small' component={Link} to={isHome ? '/profile' : '/'}>
                            {isHome ? <AccountCircleIcon /> : <DashboardIcon />}
                        </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Nav;