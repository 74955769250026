import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AlertsContext } from '../../contexts/alerts';
import { signUp } from '../../contexts/auth';

import {
    Grid,
    Button,
    TextField,
    Typography
} from '@mui/material';

const SignIn = () => {
    const navigate = useNavigate();
    const { actions } = useContext(AlertsContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (loading) return;

        setLoading(true);

        if (password !== password2) return actions.create({
            message: 'Passwords do not match',
            severity: 'error'
        });

        signUp(email, password)
            .then(() => {
                actions.create({
                    message: "Account was created successfully",
                    severity: "success"
                })
                navigate('/');
            })
            .catch(err => {
                actions.create({
                    message: 'Could not create account',
                    severity: 'error'
                })
                console.error(err);
            });
    }

    return (
        <Grid container spacing={4} component='form' onSubmit={handleSubmit} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                    Create an Account
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type='password'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Repeat Password'
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                    type='password'
                    fullWidth
                />
            </Grid>
            <Grid container item xs={12} justifyContent='space-between'>
                <Grid item>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                        disabled={loading}
                    >
                        SIGN UP
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' align='center'>
                        <Link to='/'>Already have an account?</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignIn;