import React, { useState, useCallback, useContext } from 'react';

import { MapContext } from '../../contexts/map';

import { MapPopover } from '../../components/Popovers';

import { RingButton, RingOverlay } from '../../components/Overlays/Rings';
import { FilterButton, FilterOverlay } from '../../components/Overlays/Filters';

import { Stack } from '@mui/material';

const overlays = {
    ring: <RingOverlay />,
    filter: <FilterOverlay />
}

const Overlay = () => {
    // const { ringCenter } = useContext(MapContext);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState();

    const handleClick = useCallback(t => {
        setType(t)

        if (!open) setOpen(true);
    }, [type, open]);

    const handleClose = () => setOpen(false);

    // if (!ringCenter) return null;

    const popoverChild = overlays[type] || null;

    return (
        <>
            <Stack spacing={2} sx={{ position: 'absolute', left: '20px', top: '40%', width: '40px', zIndex: 110 }}>
                <RingButton onClick={handleClick} />
                <FilterButton onClick={handleClick} />
            </Stack>
            <MapPopover open={open} handleClose={handleClose}>
                {popoverChild}
            </MapPopover>
        </>
    );
}

export default Overlay;