import React from 'react';
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import config from '../../config';

import Planes from './Planes';
import LocationHistory from './LocationHistory';
import Rings from './Rings';

function CustomMap() {
    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: config.gKey });

    if (loadError) return (
        <div>
            Error loading map, please refresh. If problem persists, please contact system admin...
        </div>
    );

    if (!isLoaded) return (
        <div>
            Loading...
        </div>
    );

    return (
        <GoogleMap
            center={{
                lat: 40,
                lng: -100
            }}
            zoom={4}
            mapContainerStyle={{
                width: '100%',
                height: '100%',
                zIndex: 100
            }}
            options={{
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                scaleControl: true,
            }}
        >
            <Planes />
            <LocationHistory />
            <Rings />
        </GoogleMap>
    )
}

export default React.memo(CustomMap);