import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import config from '../config';

import { DemoSocketContext } from './demoSocket';
import { SocketContext } from './socket';

import useInterval from '../hooks/useInterval';

const backendInstance = axios.create({
    baseURL: config.backend
});

export const MapContext = createContext();

export const MapProvider = props => {
    const { pathname } = useLocation();
    const { rows } = useContext(pathname.includes("/demo") ? DemoSocketContext : SocketContext);
    const [visibleRows, setVisibleRows] = useState();
    const [viewAllHistory, setViewAllHistory] = useState(false);
    const [histMarkers, setHistMarkers] = useState({});
    const [viewRings, setViewRings] = useState(true);
    const [ringsSize, setRingsSize] = useState([5, 10, 20]);

    const getHistory = (icao) => {
        if (pathname.includes("/demo")) return;
        
        backendInstance.post('/api/location', { icao })
            .then(res => setHistMarkers(res.data))
            .catch(console.error);
    }

    const viewIndividualHistory = useCallback((icao) => {
        if (histMarkers[icao]) {
            setHistMarkers(prev => {
                delete prev[icao];
                return { ...prev };
            });
            return;
        }

        getHistory([icao]);
    })

    const viewAllPlaneHistory = useCallback(() => {
        if (viewAllHistory) {
            setViewAllHistory(false);
            setHistMarkers({});
            return;
        }

        setViewAllHistory(true);
    })

    const getAllHistory = useCallback(() => {
        getHistory(rows.map(row => row.icao));
    })

    const handleChangeViewRings = useCallback(() => setViewRings(prev => !prev));

    const adjustRings = useCallback((nm, i) => {
        setRingsSize(prev => {
            prev[i] = nm;
            return [...prev];
        })
    })

    const handleVisibleRows = useCallback((data) => {
        if (data.length || visibleRows) setVisibleRows(data);
    })

    useEffect(() => {
        if (Object.keys(histMarkers) !== rows.length && viewAllHistory) setViewAllHistory(false);
    }, [histMarkers]);

    useInterval(() => getAllHistory(), viewAllHistory ? 10 * 1000 : null)

    const actions = {
        viewIndividualHistory,
        viewAllPlaneHistory,
        handleChangeViewRings,
        adjustRings,
        handleVisibleRows
    }

    return (
        <MapContext.Provider value={{ visibleRows, viewAllHistory, histMarkers, viewRings, ringsSize, actions }}>
            {props.children}
        </MapContext.Provider>
    )
}