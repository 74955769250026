import React, { useState, useEffect, createContext, useContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { io } from 'socket.io-client';

import config from '../config';

export const DemoSocketContext = createContext();

const maxRows = 100;

export const DemoSocketProvider = props => {
    const [socket, setSocket] = useState();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [socketLocation, setSocketLocation] = useState({});
    const [messagesCount, setMessagesCount] = useState(0);
    const { pathname } = useLocation();
    const { params: { demoID } } = matchPath("/demo/:demoID", pathname);

    const receiveRows = (data) => {

        console.log("Receiving plane data", data);

        setMessagesCount(prev => ++prev);

        setRows(prev => {
            const updatedAt = new Date();

            const rowsObj = Object.fromEntries(prev.map(row => [row.id, row]));


            Object.entries(data)
                .filter(([key, value]) => value.icao && !isNaN(key))
                .map(([key, value]) => {
                    rowsObj[value.icao] = {
                        ...value,
                        id: value.icao,
                        updatedAt,
                        src: data.src,
                        ts: data.ts
                    };
                });

            const allRows = Object.values(rowsObj);

            if (allRows.length > maxRows) allRows.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1).splice(maxRows, allRows.length - maxRows);

            return allRows;
        })
    }

    const fColumns = (data) => {
        console.log("COLUMNS", data);
        if (typeof data === 'string') {
            console.error("Error getting columns");
            return;
        }
        setColumns(Object.entries(data).map(([field, headerName]) => ({
            field,
            headerName,
            width: 150,
            editable: true,
            active: true
        })));
    }

    useEffect(() => {
        setSocket(io(
            `${config.backend}/socket/demo`,
            {
                auth: { token: demoID },
                // transports: ['websocket']
            }
        ));
    }, [pathname]);

    useEffect(() => {
        if (!socket) return;
        
        socket.on('connect', err => {
            if (err) return console.error(err);

            console.log(socket.id);
        })

        console.log("Setting listeners");
        
        socket.on("columns", fColumns);
        socket.on("telem", receiveRows);
    }, [socket]);

    return (
        <DemoSocketContext.Provider
            value={{
                rows,
                columns,
                socketLocation,
                messagesCount
            }}
        >
            {props.children}
        </DemoSocketContext.Provider>
    )
}