import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { logIn, logOut } from '../../contexts/auth';
import { AlertsContext } from '../../contexts/alerts';

import {
    Grid,
    Button,
    TextField,
    Typography
} from '@mui/material';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { actions } = useContext(AlertsContext)

    const handleSubmit = (e) => {
        e.preventDefault();
        logIn(email, password)  
            .then(() => console.log('Sign in successful'))
            .catch(err => {
                console.error(err);
                actions.create({
                    message: 'Incorrect login credentials',
                    severity: 'error'
                })
            })
    }

    return (
        <Grid container spacing={4} component='form' onSubmit={handleSubmit} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                    Sign In
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type='password'
                    fullWidth
                />
            </Grid>
            <Grid container item xs={12} justifyContent='space-between'>
                <Grid item>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                    >
                        SIGN IN
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' align='center'>
                        <Link to='/signup'>Don't have an account?</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignIn;