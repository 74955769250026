import React from 'react';
import { useLocation } from 'react-router-dom';
import ThemeContext from './theme';
import { AlertsProvider } from './alerts';
import { AuthProvider } from './auth';
import { SocketProvider } from './socket';
import { DemoSocketProvider } from './demoSocket';
import { MapProvider } from './map';

const AllContexts = ({ children }) => {
    const { pathname } = useLocation();

    const Socket = pathname.includes("/demo") ? DemoSocketProvider : SocketProvider;

    return (
        <ThemeContext>
            <AlertsProvider>
                <AuthProvider>
                    <Socket>
                        <MapProvider>
                            {children}
                        </MapProvider>
                    </Socket>
                </AuthProvider>
            </AlertsProvider>
        </ThemeContext>
    );
}

export default AllContexts;