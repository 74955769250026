import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';

import {
    Container,
    Grid,
    IconButton
} from '@mui/material';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import CustomRouter from '../../routes';

const sizes = [0, 33, 100];

const SideTray = () => {
    const [expanded, setExpanded] = useState(0);
    const { user } = useContext(AuthContext);
    const { pathname } = useLocation();

    const expand = useCallback(() => {
        if (expanded === 2) return;
        setExpanded(prev => prev + 1);
    })

    const shrink = useCallback(() => {
        if (!expanded) return;
        setExpanded(prev => prev - 1);
    })

    useEffect(() => {
        if ((!user) || pathname.includes("/demo")) setExpanded(1);
    }, []);

    return (
        <div style={{ position: 'absolute', right: 0, top: '40px', height: 'calc(100% - 40px)' }}>
            <div style={{ position: 'absolute', top: 0, right: `${sizes[expanded] === 100 ? 96.5 : sizes[expanded]}vw`, height: '100%', minWidth: '40px', transition: 'all 0.5s ease-in-out' }}>
                <Grid container style={{ width: '40px', marginTop: '42vh' }}>
                    <Grid item xs={12} style={{ zIndex: 102 }}>
                        {expanded !== 2 ? (
                            <IconButton
                                disabled={expanded === 2}
                                onClick={expand}
                            >
                                <ArrowBackIos />
                            </IconButton>

                        ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ zIndex: 102 }}>
                        {expanded ? (
                            <IconButton
                                disabled={!expanded}
                                onClick={shrink}
                            >
                                <ArrowForwardIos />
                            </IconButton>

                        ) : null}
                    </Grid>
                </Grid>
            </div>
            <div 
                style={{ 
                    height: '100%', 
                    width: `${sizes[expanded]}vw`, 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 101, 
                    backgroundColor: '#FFFFFF', 
                    transition: 'all 0.5s ease-in-out', 
                    overflow: 'hidden' 
                }}
            >
                <Container sx={{ marginTop: '24px' }}>
                    <CustomRouter display={expanded > 0} />
                </Container>
            </div>
        </div>
    );
}

export default SideTray;