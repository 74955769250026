import React, { useContext } from 'react';

import { MapContext } from '../../contexts/map';

import {
    FormControlLabel,
    Grid,
    Typography,
    IconButton,
    Switch,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const FilterButton = ({ onClick }) => {
    return (
        <IconButton onClick={() => onClick('filter')}>
            <FilterAltIcon />
        </IconButton>
    );
}

export const FilterOverlay = () => {
    const { viewAllHistory, actions: mapActions } = useContext(MapContext);

    return (
        <Grid container alignItems='center' spacing={2} sx={{ padding: '16px' }}>
            <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                    Change Map Filters
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel 
                    control={<Switch checked={viewAllHistory} onChange={mapActions.viewAllPlaneHistory}/>}
                    label='Location History'
                />
            </Grid>
        </Grid>
    );
}