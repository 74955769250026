import React, { useState, useCallback } from 'react';

import TabPanel from '../../components/Tabs/TabPanel';
import SavedSockets from './SavedSockets';

import {
    Grid,
    Tabs,
    Tab
} from '@mui/material';
import CreateSocket from './CreateSocket';

const Profile = () => {
    const [open, setOpen] = useState(0);
    const [viewing, setViewing] = useState();

    const viewSocket = useCallback((socketID) => {
        setViewing(socketID);
        setOpen(1);
    }, []);

    return (
        <Grid container spacing={4} style={{ margin: 0, width: '100%', marginTop: '-32px' }}>
            <Grid item xs={12}>
                <Tabs
                    value={open}
                    onChange={(e, newValue) => setOpen(newValue)}
                    centered
                >
                    <Tab
                        label='Create'
                    />
                    <Tab
                        label='Established'
                    />
                </Tabs>
            </Grid>

            <TabPanel isOpen={open === 0}>
                <CreateSocket viewSocket={viewSocket} />
            </TabPanel>

            <TabPanel isOpen={open === 1} >
                <SavedSockets viewing={viewing} />
            </TabPanel>

        </Grid>
    );
}

export default Profile;