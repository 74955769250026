import React, { useState, useEffect, useRef } from 'react';

import {
    Box,
    Slider,
    TextField
} from '@mui/material';

import SyncIcon from '@mui/icons-material/Sync';

const BetweenFilterInput = props => {
    const { item, applyValue, focusElementRef = null } = props;
    
    const filterTimeout = useRef();
    const [filterValueState, setFilterValueState] = useState(item.value ?? '');

    const [applying, setIsApplying] = useState(false);

    const getRange = () => {

        const { columnField } = item;

        if (['geoAlt', 'baroAlt'].includes(columnField)) return [0, 65000];

        if (['hVelo', 'vVelo'].includes(columnField)) return [0, 2000];

        if (columnField === 'track') return [0, 360];
    }

    useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    useEffect(() => {
        const itemValue = item.value ?? [undefined, undefined];
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (newValue) => {
        console.log('new value', newValue);
        clearTimeout(filterTimeout.current);
        setFilterValueState(newValue);

        setIsApplying(true);
        filterTimeout.current = setTimeout(() => {
            setIsApplying(false);
            applyValue({ ...item, value: newValue });
        }, 500);
    };

    const handleUpperFilterChange = (event) => {
        updateFilterValue(filterValueState[0], event.target.value);
    };
    const handleLowerFilterChange = (event) => {
        updateFilterValue(event.target.value, filterValueState[1]);
    };

    // console.log(item, applyValue, props);

    const range = getRange();

    console.log(range, filterValueState);

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <Slider 
                min={range[0]}
                max={range[1]}
                step={Math.floor((range[1] - range[0]) / 100)}
                value={filterValueState}
                onChange={(e, newValue) => updateFilterValue(newValue)}
                valueLabelDisplay='auto'
            />
            {/* <TextField
                name="lower-bound-input"
                placeholder="From"
                label="From"
                variant="standard"
                value={Number(filterValueState[0])}
                onChange={handleLowerFilterChange}
                type="number"
                inputRef={focusElementRef}
                sx={{ mr: 2 }}
            />
            <TextField
                name="upper-bound-input"
                placeholder="To"
                label="To"
                variant="standard"
                value={Number(filterValueState[1])}
                onChange={handleUpperFilterChange}
                type="number"
                InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
            /> */}
        </Box>
    );
}
 
export default BetweenFilterInput;