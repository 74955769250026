import React, { useContext } from 'react';

import { AuthContext } from '../contexts/auth';

import {
    SignIn, 
    SignUp
} from '../views/Auth';
import {
    Dashboard,
    Profile
} from '../views/Data';

const demo = {
    path: '/demo/:demoID',
    component: <Dashboard />,
    exact: false
}

function useRoutes() {
    const { user } = useContext(AuthContext);

    if (user) return [
        {
            path: '/',
            component: <Dashboard />,
            exact: true
        },
        {
            path: '/profile',
            component: <Profile />,
            exact: true
        },
        {
            path: '/signin',
            component: <SignIn />,
            exact: true
        },
        demo
    ];

    return [
        {
            path: '/signup',
            component: <SignUp />
        },
        {
            path: '*',
            component: <SignIn />
        },
        demo
    ]
};

export default useRoutes;