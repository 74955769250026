import React from 'react';

import Overlay from './Overlay';
import CustomMap from '../../components/Maps';

const MapView = () => {
    return (
        <>
            <Overlay />
            <CustomMap />
        </>
    );
}

export default MapView;