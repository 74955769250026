import React, { useState, useEffect, useContext } from 'react';

import { SocketContext } from '../../contexts/socket';

import {
    Grid,
    Card,
    Button,
    IconButton,
    Typography
} from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const SavedSockets = (props) => {
    const { getSockets, getActiveSockets, listen, stopSocket, createDemo } = useContext(SocketContext);
    const [sockets, setSockets] = useState([]);
    const [expanded, setExpanded] = useState(-1);

    const connectSocket = (i) => {
        const [socketData, connected] = sockets[i];

        if (connected) return;

        listen(socketData.id, socketData.type)
            .then(() => setSockets(prev => {
                prev[i][1] = true;
                return [...prev];
            }))
            .catch(console.error);
    }

    const disconnectSocket = (i) => {

        const [socketData, connected] = sockets[i];

        if (!connected) return;

        stopSocket(socketData.id);

        setSockets(prev => {
            prev[i][1] = false;
            return [...prev];
        });

    }

    const getLink = (id) => {
        createDemo(id);
    }

    useEffect(() => {
        Promise.all([
            getSockets(),
            getActiveSockets()
        ])
            .then(([socketsData, activeSockets]) => {
                console.log(socketsData, activeSockets);
                setSockets(socketsData.map(data => [data, activeSockets.includes(data.id)]));
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (sockets && props.viewing) {
            const index = sockets.findIndex(([socket]) => socket.id == props.viewing);
            setExpanded(index);
        }
    }, [sockets.length, props.viewing]);

    console.log(sockets);

    const socketData = expanded > -1 ? sockets[expanded][0] : null

    return sockets.map((s, i) => (
        <Grid item xs={12} key={i}>
            <Card style={{ width: 'calc(100% - 24px)', padding: '12px' }}>
                <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                    <Grid item>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                                {s[1] ? (
                                    <IconButton onClick={() => disconnectSocket(i)}>
                                        <StopIcon fontSize='small' style={{ color: 'red' }} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => connectSocket(i)}>
                                        <PlayArrowIcon fontSize='small' style={{ color: 'limegreen' }} />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item>
                                {s[0].host}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        {expanded === i ? (
                            <IconButton onClick={() => setExpanded(-1)}>
                                <ArrowDropUpIcon />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setExpanded(i)}>
                                <ArrowDropDownIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid container item xs={12} alignItems='center' spacing={1}>
                        {socketData ? (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2'>Port: {socketData.port}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2'>Type: {socketData.type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2'>Topic: {socketData.topic}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle2'>Username: {socketData.username}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => getLink(socketData.id)}
                                    >
                                        CREATE DEMO
                                    </Button>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Card >
        </Grid >
    ));
}

export default SavedSockets;