import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Marker,
    Circle,
    InfoBox
} from "@react-google-maps/api";

import { DemoSocketContext } from '../../contexts/demoSocket';
import { SocketContext } from '../../contexts/socket';
import { MapContext } from '../../contexts/map';

import HubMarker from '../../assets/svgs/hub';

const Rings = () => {
    const { pathname } = useLocation();
    const { socketLocation } = useContext(pathname.includes("/demo") ? DemoSocketContext : SocketContext);
    const { viewRings, ringsSize } = useContext(MapContext);

    if (!viewRings) return null;

    const milesToMeters = (miles) => miles * 1852;
    const milesWithLat = (center, miles) => center.lat + ((miles - 0.8) / 60);

    console.log(socketLocation);

    return Object.entries(socketLocation).map(([src, center]) => (
        <>
            {ringsSize.map((miles, i) => (
                <Circle
                    center={center}
                    radius={milesToMeters(miles)}
                    options={{
                        strokeColor: '#381ECF',
                        fillOpacity: 0,
                        strokeWeight: 2
                    }}
                    key={i}
                />
            ))}

            <Marker
                position={center}
                title={src}
                icon={{
                    path: HubMarker,
                    anchor: new window.google.maps.Point(-5, 15),
                }}
            />

            {ringsSize.map((miles, i) => (
                <InfoBox
                    position={{
                        lat: milesWithLat(center, miles),
                        lng: center.lng
                    }}
                    options={{
                        closeBoxURL: '',
                        enableEventPropagation: true
                    }}
                    key={i}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            textAlign: 'center',
                            width: '40px'
                        }}
                    >
                        {miles} nm
                    </div>
                </InfoBox>
            ))}

        </>
    ));
}

export default React.memo(Rings);