import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import config from '../config';
import { initializeApp } from 'firebase/app';

import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    getIdToken
} from 'firebase/auth';

const app = initializeApp({
    apiKey: config.gKey,
    authDomain: `${config.projectID}.firebaseapp.com`,
    databaseURL: `https://${config.projectID}.firebaseio.com`,
    projectId: config.projectID,
    storageBucket: `${config.projectID}.appspot.com`,
    messagingSenderId: config.senderID,
    appId: config.appID,
})

const auth = getAuth(app);

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        onAuthStateChanged(auth, userAuth => {
            console.log(userAuth);
            if (userAuth) {
                setUser(userAuth.uid);
                setTimeout(() => {
                    window.location.reload();
                }, 59 * 60 * 1000 /* 59 minutes */)
            };

            if ((!userAuth) && user) setUser(userAuth.uid);

            if (!loaded) setLoaded(true)
        })
    }, []);

    return loaded ? (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    ) : null;
}

export const logIn = (email, password) => new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
        .then(() => resolve(true))
        .catch(reject);
})

export const logOut = () => new Promise((resolve, reject) => {
    signOut(auth)
        .then(() => resolve(true))
        .catch(reject);
})

export const signUp = (email, password) => new Promise((resolve, reject) => {
    axios.post(
        `${config.backend}/api/users/create`, 
        { email, password }
    )
        .then(resolve)
        .catch(err => {
            console.error(err.message);
            reject(new Error(err.message));
        })
})

export const getToken = () => {
    if (!auth.currentUser) return Promise.reject(new Error('No user currently signed in'));
    return getIdToken(auth.currentUser, true);
}