import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios';

import config from '../config';

import { AuthContext, getToken } from './auth';

import BetweenFilterInput from '../components/DataGrid/BetweenFilterInput';

const backendInstance = axios.create({
    baseURL: `${config.backend}`
});

const betweenOperator = (range) => ({
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) return null;

        if (filterItem.value[0] == null || filterItem.value[1] == null) return null;

        return ({ value }) => (value !== null && filterItem.value[0] <= parseFloat(value) && parseFloat(value) <= filterItem.value[1]);
    },
    InputComponent: BetweenFilterInput,
    InputComponentProps: (props) => ({ ...props, range })
});

const maxRows = 100

export const SocketContext = createContext();

export const SocketProvider = props => {
    const [socket, setSocket] = useState();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [messagesCount, setMessagesCount] = useState(0);
    const [socketLocation, setSocketLocation] = useState({});
    const [socketType, setSocketType] = useState();
    const { user } = useContext(AuthContext);

    const receiveRows = (data) => {

        setMessagesCount(prev => ++prev);

        setRows(prev => {
            const updatedAt = new Date();

            const rowsObj = Object.fromEntries(prev.map(row => [row.id, row]));

            Object.entries(data)
                .filter(([key, value]) => value.icao && !isNaN(key))
                .map(([key, value]) => {
                    rowsObj[value.icao] = {
                        ...value,
                        id: value.icao,
                        updatedAt,
                        src: data.src,
                        ts: data.ts
                    };
                });

            const allRows = Object.values(rowsObj);

            if (allRows.length > maxRows) allRows.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1).splice(maxRows, allRows.length - maxRows);

            return allRows;
        })
    }

    const fColumns = (data) => {
        if (typeof data === 'string') {
            console.error("Error getting columns");
            return;
        }

        const columnDefault = {
            width: 150,
            editable: true,
            align: 'center',
            headerAlign: 'center'
        }

        const tColumns = Object.entries(data).map(([field, headerName]) => {
            const c = {
                field,
                headerName,
                hide: ['geoAlt', 'nacp', 'nacv', 'nicBaro', 'nic', 'surf', 'sigStr', 'sigQ', 'fps'].includes(field),
                ...columnDefault
            };

            if (['baroAlt', 'geoAlt'].includes(c.field)) c.filterOperators = [betweenOperator([0, 65000])];

            if (['hVelo', 'vVelo'].includes(c.field)) c.filterOperators = [betweenOperator([0, 2000])];

            if (c.field === 'track') c.filterOperators = [betweenOperator([0, 360])];

            return c;
        })

        tColumns.splice(6, 0, {
            field: 'tsLocal',
            headerName: 'Message Time (Local)',
            ...columnDefault
        })

        setColumns(tColumns);
    }

    const receiveLocation = ({ src, center }) => {
        if (!socketLocation[src]) setSocketLocation(prev => ({ ...prev, [src]: center }))
    };

    const establishSocket = (socketData) => new Promise((resolve, reject) => {
        console.log('establishing socket', socketData);
        getToken()
            .then(token => backendInstance.post(
                '/api/sockets/create',
                socketData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            ))
            .then(res => {
                socket.on("telem", receiveRows);
                fColumns(res.data.columns);

                if (res.data.socketID) resolve(res.data.socketID)
                else socket.once("new-room", socketID => resolve(socketID));
            })
            .catch(err => {
                reject(err);
            })
    })

    const getSockets = () => new Promise((resolve, reject) => {
        console.log("Getting user sockets");
        getToken()
            .then(token => backendInstance.get(
                '/api/sockets',
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            ))
            .then(res => {
                console.log("Getting sockets data");
                resolve(res.data);
            })
            .catch(reject);
    })

    const getActiveSockets = () => new Promise((resolve) => {
        console.log("getting active sockets");
        socket.emit('active-rooms', (rooms) => {
            console.log("Rooms:", rooms);
            resolve(rooms)
        });
    })

    const createDemo = (socketID) => {
        getToken()
            .then(token => backendInstance.post(
                '/api/demo/create',
                { socketID },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            ))
            .then(res => {
                console.log("demo res data:", res.data);
                console.log(window.location);
                const url = `${window.location.origin}/demo/${res.data.demoID}`;

                navigator.clipboard.writeText(url);
                alert(`Copied ${url}`);
            })
            .catch(console.error);
    }

    const listen = (id, type) => new Promise((resolve) => {
        socket.emit("join-room", id, (c) => {
            fColumns(c);
            resolve(true);
        });
        socket.on("telem", receiveRows);
        socket.on("sensor-center", receiveLocation)
        setSocketType(type)
    })

    const stopSocket = (id) => {
        socket.emit('leave-room', id);
    }

    const moveRings = (latLng) => {
        getToken()
            .then(token => backendInstance.post(
                "/api/sockets/location",
                latLng,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            ))
            .then(response => console.log(response))
            .catch(console.error);
    }

    useEffect(() => {
        if (!socket) return;

        console.log(socket);

        socket.on('connect', err => {
            console.log(socket.id);
            if (err) console.error(err);
        })

        return () => {
            socket.disconnect();
        }
    }, [socket]);

    useEffect(() => {
        if ((!socket) && user) {
            console.log("attempting log in");
            getToken()
                .then(token => setSocket(io(
                    `${config.backend}/socket/user`,
                    {
                        auth: { token },
                        transports: ['websocket']
                    }
                )))
                .catch(console.error);
        }
    }, [user]);

    return (
        <SocketContext.Provider
            value={{
                socket,
                rows,
                columns,
                socketLocation,
                messagesCount,
                socketType,
                establishSocket,
                getSockets,
                getActiveSockets,
                createDemo,
                listen,
                moveRings,
                stopSocket
            }}
        >
            {props.children}
        </SocketContext.Provider>
    )
}